<template>
  <div class="tasks">
    <div class="padding_top"></div>
    <b-container class="bkonline_tasks" v-if="tasks">
      <b-row align-h="center">
        <b-col cols="auto">
          <h1 class="page_title">Состояние</h1>
          <p v-if="tasks.active_task" class="page_subtitle">
            Уровень {{ tasks.active_task.level }}
          </p>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col cols="12" md="8">
          <b-row class="tasks_wrapper">
            <div
              class="task_card__wrapper"
              v-for="(task, index) in tasks.tasks"
              :key="index"
            >
              <button
                @click="to('Task', 'exe')"
                v-if="task.num == tasks.active_task.num && activeTask"
                class="task_card"
                :class="task.status"
              >
                <p class="task_card__number">{{ task.num }}</p>
              </button>

              <div
                v-else-if="task.num == tasks.active_task.num"
                class="task_card"
                :class="task.status"
                @click="
                  makeToast('Следующее задание можно будет выполнить завтра')
                "
              >
                <p class="task_card__number">{{ task.num }}</p>
              </div>

              <div v-else class="task_card" :class="task.status">
                <p class="task_card__number">{{ task.num }}</p>
              </div>
            </div>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="tasks.editing_tasks.length" align-h="center">
        <p class="tasks_subtitle">Задания для исправления</p>
      </b-row>

      <b-col cols="12" md="8">
        <b-row class="tasks_wrapper">
          <div
            class="task_card__wrapper"
            v-for="(task, index) in tasks.editing_tasks"
            :key="index"
          >
            <button
              @click="to('Task', 'edit', index)"
              class="task_card editing"
            >
              <p class="task_card__number">{{ task.task.num }}</p>
              <p class="task_card__lvl">ур. {{ task.task.level }}</p>
            </button>
          </div>
        </b-row>
      </b-col>
    </b-container>

    <b-modal
      id="motivation"
      modal-class="motivation_modal__modal"
      dialog-class="motivation_modal__dialog"
      content-class="motivation_modal__content"
      body-class="motivation_modal__body"
      centered
      hide-footer
      hide-header
      size="md"
      static
      no-close-on-backdrop
      #default="{ close }"
    >
      <b-container>
        <b-row align-h="center" align-v="center">
          <div class="motivation_img__wrapper">
            <button class="motivation_img__close_button" @click="close">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="close__button"
                  d="M19 5L5 19M5.00001 5L19 19"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>

            <img
              v-if="user && user.motivation"
              class="motivation_img__img"
              :src="
                $store.state.storageUrl +
                '/app/motivations/' +
                user.motivation.img
              "
            />
          </div>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import axios from "axios";
moment.locale("ru");

export default {
  name: "Tasks",
  methods: {
    ...mapActions(["getTasks", "getUser"]),
    makeToast(msg, title = "BKteams") {
      this.$bvToast.toast(msg, {
        title: title,
        autoHideDelay: 3000,
      });
    },
    to(path, type, active) {
      if (type == "exe" && this.tasks.editing_tasks.length != 0) {
        this.makeToast("Доработайте задания чтобы выполнить следующее");
      } else {
        if (this.$route.path != path)
          this.$router.push({
            name: path,
            params: { type: type, active: active },
          });
        localStorage.setItem("typeTask", type);
        localStorage.setItem("activeTask", active);
      }
    },
  },
  computed: {
    tasks() {
      return this.$store.state.bkonline.tasks;
    },
    activeTask() {
      return this.$store.state.bkonline.activeTask;
    },
    user() {
      return this.$store.state.user.user;
    },
    subscription() {
      return this.$store.state.user.subscription;
    },
  },
  async mounted() {
    try {
      this.$store.commit("SET_LOADING", true);

      if (localStorage.getItem("token")) {
        await this.getUser();

        // Getting task

        // For admin
        if (this.user.role_id == 1) {
          await this.getTasks();

          if (
            moment(this.tasks.last_date_completing).format("YYYY-MM-DD") ==
            moment(new Date()).format("YYYY-MM-DD")
          ) {
            if (this.user.motivationViewed != true) {
              this.$bvModal.show("motivation");

              try {
                this.$store.commit("SET_LOADING", true);
                await axios.post("update_motivation_view", {
                  motivation_id: this.user.motivation.id,
                });

                this.$store.commit("SET_LOADING", false);
              } catch (e) {
                this.$store.commit("SET_LOADING", false);
                console.error(e);
              }
            }
          }
        } else {
          // Cheacking subscription for user
          if (this.subscription && this.subscription.expiration_date) {
            //  If expiration date ok
            if (
              moment(this.subscription.expiration_date).format("YYYY-MM-DD") >=
              moment(new Date()).format("YYYY-MM-DD")
            ) {
              await this.getTasks();

              if (
                moment(this.tasks.last_date_completing).format("YYYY-MM-DD") ==
                moment(new Date()).format("YYYY-MM-DD")
              ) {
                if (this.user.motivationViewed != true) {
                  this.$bvModal.show("motivation");

                  try {
                    this.$store.commit("SET_LOADING", true);
                    await axios.post("update_motivation_view", {
                      motivation_id: this.user.motivation.id,
                    });

                    this.$store.commit("SET_LOADING", false);
                  } catch (e) {
                    this.$store.commit("SET_LOADING", false);
                    console.error(e);
                  }
                }
              }
            } else {
              this.$router.push({ name: "Subscribe" });
            }
          } else {
            this.$router.push({ name: "FreeWeek" });
          }
        }
      } else {
        // If not auth
        this.$router.push({ name: "Login" });
      }

      this.$store.commit("SET_LOADING", false);
    } catch (e) {
      this.$store.commit("SET_LOADING", false);
      console.error(e);
    }
  },
};
</script>

<style lang="scss">
.tasks_wrapper {
  position: relative;
  padding-bottom: 30px;
}
.task_card__wrapper {
  border: 5px solid $gray;
}

@media only screen and (max-width: 768px) {
  .task_card__wrapper {
    height: 25vw;
    width: 25vw;
  }
}
@media only screen and (min-width: 768px) {
  .task_card__wrapper {
    height: 5vw;
    width: 5vw;
  }
}

.task_card {
  height: 100%;
  width: 100%;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task_card.await_execution {
  background-color: $accent;
}
.task_card.completed {
  background-color: $green;
}
.task_card.closed {
  background-color: $gray__light;
}
.task_card.editing {
  background-color: $orange;
}
.task_card__number {
  font-weight: 700;
  font-size: 22px;
  line-height: 20px;
  margin: 0;
}
.task_card__lvl {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin: 0;
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: $black;
}
.bkonline_tasks {
  padding-top: 30px;
}

.motivation_img__wrapper {
  position: relative;

  height: 0;
  width: 100%;
  padding-bottom: 100%;

  overflow: hidden;
}
.motivation_img__img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.motivation_img__close_button {
  position: absolute;
  z-index: 50;
  top: 15px;
  right: 15px;
}

// Modal
.motivation_modal__modal {
  margin: 0 auto;
}
.motivation_modal__dialog {
  margin: 0 auto;
  max-height: 100vh;
}
.motivation_modal__content {
  background-color: unset;
  border: none;
}
.motivation_modal__body {
  padding: 0;
  margin: 15px;

  background-color: rgba($color: $gray, $alpha: 0.98);
}
</style>
